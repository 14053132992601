import Voter from '@/common/classes/voter';
import { useRootStore } from '@/user/stores/root';


export function menuItems () {
    const rootStore = useRootStore();
    const voter = new Voter();

    return [
        {
            name: 'Claims/Projects',
            // route.name is used as the unique key in the v-for loop
            route: { name: 'projects' },
            icon: 'shield',
            visibleOnlyIfCanModify: false,
        },
        {
            name: 'Energy',
            route: { name: 'energy' },
            icon: 'energy',
            visibleOnlyIfCanModify: true,
        },
        {
            name: 'Transfer Stations',
            route: { name: 'transfer-stations' },
            icon: 'transfer',
            visibleOnlyIfCanModify: true,
        },
        {
            name: 'Dashboards',
            route: { name: 'reports' },
            icon: 'reports',
            visibleOnlyIfCanModify: false,
        },
        {
            name: 'Admin',
            route: { name: 'admin' },
            icon: 'gear',
            visibleOnlyIfCanModify: true,
        },
    ].filter((item) => {
        return !item.visibleOnlyIfCanModify
            || 'ALL' === rootStore.currentCompany
            || (rootStore.currentCompany && voter.canCompanyModify());
    });
}
